<template>
  <sgv-table
    :items="items"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.code">
          <button
            v-if="item.type === 'add'"
            type="button"
            class="btn btn-success btn-sm mr-1"
            @click="addData(item)">
            เพิ่ม
          </button>
          <button
            v-else-if="item.type === 'update'"
            type="button"
            class="btn btn-warning btn-sm mr-1"
            @click="updateData(item)">
            แก้ไข
          </button>
          <button
            v-else-if="item.type === 'delete'"
            type="button"
            class="btn btn-danger btn-sm mr-1"
            @click="deleteData(item)">
            ลบ
          </button>
          {{item.code}}
        </td>
        <td v-if="hidden.name">{{item.name}}</td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import {
  LIST_VALIDATION,
  CREATE_LABEL,
  UPDATE_LABEL,
  DESTROY_LABEL
} from './graph'

export default {
  props: {
    labelType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Label${this.$form.capitalize(this.labelType)}Validation`,
      headers: [
        {text: 'รหัส', value: 'code', filter: true},
        {text: 'ชื่อ', value: 'name', filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      labels: {
        add: [],
        update: [],
        delete: []
      },
    }
  },
  apollo: {
    labels: {
      query () {
        return LIST_VALIDATION(this.templateType)
      },
      variables () {
        return {labelType: this.labelType}
      },
      debounce: 150,
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    items () {
      return [
        ...this.labels.add.map(v => ({
          id: v.id,
          type: 'add',
          code: v.code,
          name: v.name
        })),
        ...this.labels.update.map(v => ({
          id: v.id,
          type: 'update',
          code: v.code,
          name: v.name
        })),
        ...this.labels.delete.map(v => ({
          id: v.id,
          type: 'delete',
          code: v.code,
          name: v.name
        }))
      ]
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.labels.refetch()
    },
    addData (item) {
      this.$apollo.mutate({
        mutation: CREATE_LABEL(this.templateType),
        variables: {
          labelType: this.labelType,
          input: {
            code: item.code,
            name: item.name
          }
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData (item) {
      this.$apollo.mutate({
        mutation: UPDATE_LABEL(this.templateType),
        variables: {
          labelType: this.labelType,
          labelId: item.id,
          input: {
            name: item.name
          }
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteData (item) {
      this.$apollo.mutate({
        mutation: DESTROY_LABEL(this.templateType),
        variables: {
          labelType: this.labelType,
          labelId: item.id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
