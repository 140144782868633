<template>
  <sgv-table
    :rKey="rKey"
    :items="labels"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr class="">
        <td
          v-if="hidden.code"
          @click="toDetail(item.id)"
          class="pointer">
          {{item.code}}
        </td>
        <td v-if="hidden.name">
          {{item.name}}
          <div
            v-for="device in item.devices"
            :key="device.id"
            class="text-primary">
            <small>
              <fa icon="print" class="mr-1"></fa>
              {{device.code}} ({{device.name}})
            </small>
          </div>
        </td>
      </tr>
    </template>

    <button
      v-if="$auth.hasRole(`label:${labelType}:add`)"
      type="button"
      class="btn btn-link text-success"
      slot="action" @click="toDetail(0)">
      เพิ่ม
    </button>

  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_LABEL } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    labelType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Label${this.$form.capitalize(this.labelType)}Detail`,
      rKey: `Label${this.$form.capitalize(this.labelType)}ListTable`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', filter: true, sort: true},
        {text: 'ชื่อ', value: 'name', filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      labels: [],
    }
  },
  apollo: {
    labels: {
      query () {
        return LIST_LABEL(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          labelType: this.labelType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      return filter
    },
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {labelId: id}
      })
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
